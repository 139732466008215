$(document).ready(function () {

    $.fn.player = function (url) {
      console.log(url);
        jwplayer("videoPlayer").setup({
            autostart: true,
            file: url,
            width: "100%",
            aspectratio: "16:9",
        });
        jwplayer("videoPlayer").addButton(
           "assets/hd.png",
            "HD",
            function () {
                document.getElementById("HdSdstatus").click();
            },
            "HD"
        );
        jwplayer("videoPlayer").on('error', function() {
          console.log("Error");
        jwplayer("videoPlayer").load({
            file:"../../../assets/noURL.mp4",
            image:"../../../assets/error.jpg"
          });
          jwplayer("videoPlayer").play();
        });
    }

    $.fn.HdPlayer = function (url) {
      jwplayer("videoPlayer").setup({
        autostart: true,
        file: url,
        width: "100%",
        aspectratio: "16:9",
      });
      jwplayer("videoPlayer").addButton(
        "assets/sd.png",
        "SD",
        function () {
          document.getElementById("HdSdstatus").click();
        },
        "SD"
      );
      jwplayer("videoPlayer").on('error', function() {
      jwplayer("videoPlayer").load({
        file:"../../../assets/noURL.mp4",
        image:"../../../assets/error.jpg"
      });
      jwplayer("videoPlayer").play();
    });
    }

    $.fn.FmPlayer = function(FmplayUrl){
    console.log(FmplayUrl)
      var player = jwplayer('fmPlayer');
      player.setup({
        file: FmplayUrl.mediaUrl,
        image: FmplayUrl.mediaArt,
        autostart: true,
        width: "100%",
        aspectratio: "16:9"
      });

      // player.on('meta', function(e) {
      //   console.log(e);
      //   if (e.metadata) {
      //     var title = e.metadata.title;
      //     var artist = e.metadata.artist;
      //     var imageUrl = e.metadata.url;

      //     // title ? titleDiv.innerHTML = title : titleDiv.innerHTML = 'Unknown';
      //     // artist ? artistDiv.innerHTML = artist : artistDiv.innerHTML = 'Unknown';
      //     // imageUrl ? imageDiv.src = imageUrl : imageDiv.src = 'assets/jwlogo.png';
      //   };
      // });

      // // Handle reset of player at end of content
      // player.on('error', function(e) {
      //   if (e.message === 'The live stream is either down or has ended') {
      //     player.load({
      //       file: 'assets/index.m3u8'
      //     });
      //   }
      // });
    }  
});


